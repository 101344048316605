// import { Provider } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "./App.css";
// import AppRouter from "./config/appRouter";
// import store from "./config/store";
// function App() {

//   return (
//     <Provider store={store}>
//       <AppRouter />
//       <ToastContainer />
//     </Provider>
//   );
// }

// export default App;
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppRouter from "./config/appRouter";
import store from "./config/store";

function App() {
  window.addEventListener("scroll", function () {
    var scrollPosition = window.scrollY;

    if (scrollPosition === 0) {
      document.body.classList.add("scroll-border");
    } else {
      document.body.classList.remove("scroll-border");
    }
  });
  return (
    <Provider store={store}>
      <div className="appbar_area">
       Enjoy Free Delivery on Orders of <span style={{fontWeight:"600",margin:"0 5px"}}>Rs 4,000</span> or More!
      </div>
      <AppRouter />
      <ToastContainer />
    </Provider>
  );
}

export default App;
